.error { color: red; font-size: .85rem; margin-top: .3rem;}
.pointer { cursor: pointer }
.move { cursor: move }
.hover:hover { text-decoration: underline; cursor: pointer }

.dark-bg {
  color: white;
  background: rgba(0,0,0,0.7);
}

.navbar-brand > img {
  height: 24px;
  margin-top: -4px;
  margin-right: 12px;
}

.avatar {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: -4px;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }
a:active { text-decoration: none; }
span.link, span.nav-link { cursor: pointer }
span.link:hover, span.nav-link:hover { text-decoration: underline; }

.list-group-item.active {
  color: white;
  background-color: $primary;
  border-color: $primary;
}

.image-bg {
    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.horizontal-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-thumb {
  display: inline-block;
  margin-right: .5rem;
  height: 150px;
}

.modal-xs { max-width: 250px; }
.modal-bottom > .modal-dialog { align-items: flex-end; }

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
