/* Customized Defaults */
$primary: #000;
$secondary: #000;
$tertiary: #F7A600;
$dark: #212529;
$black: #000;

$active: #F4803C; // orange
$active-inverse: #fff;

$body-bg: #EEEAE9;
// $body-bg: #213944;
// $body-color: #fff;

$font-family-sans-serif:      "Rubik";
$font-size-base:              .875rem;
$h1-font-size:                $font-size-base * 2.0;
$h2-font-size:                $font-size-base * 1.8;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.2;
$h6-font-size:                $font-size-base * 0.75;

$list-group-action-active-color:    'white';
$list-group-action-active-bg:       'black';

$hr-color: #A59997;

$border-color: #DCD3D1;

$nav-tabs-border-color: #EEEAE9;
$nav-tabs-link-active-border-color: #DCD3D1 #DCD3D1 #f00;

$border-radius: 2px;
$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius;
$btn-border-radius-lg:        $border-radius;

$box-shadow:    0 .5rem 1rem rgba($black, .15);
$box-shadow-sm: 0 0 .5rem rgba($black, .1);

$form-check-input-width:                  1.5em;
// $form-check-min-height:                   $font-size-base * $line-height-base;
$form-check-padding-start:                $form-check-input-width + .5em;
$form-check-margin-bottom:                .3rem;

$breadcrumb-padding-y: 0.25rem;
$breadcrumb-font-size: 12.25px;
$breadcrumb-active-color: #000;
$breadcrumb-divider-color: #A59997;

$breadcrumb-item-padding-x: .5rem;

$white:    #fff !default;
$gray-100: #EEEAE9 !default; // f8f9fa, eeeae9 is the tan background
$gray-200: #e9ecef !default; // e9ecef
$gray-300: #DED8D3 !default; // dee2e6, this is the gray for borders
$gray-400: #ced4da !default; // ced4da
$gray-500: #adb5bd !default; // adb5bd
$gray-600: #6c757d !default; // 6c757d
$gray-700: #495057 !default; // 495057
$gray-800: #343a40 !default; // 343a40
$gray-900: #212529 !default; // 212529
$black:    #0C0909 !default; // 000

$nav-tabs-border-color:             $body-bg;
$nav-tabs-link-hover-border-color:  $gray-100 $gray-100 $black;
$nav-tabs-link-active-color:        $black;
$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-link-active-border-color: $gray-100 $gray-100 $black;

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap');
@import '../global.scss';
@import '../animation.scss';
@import '../form.scss';
@import '../slider.scss';
@import '../switch.scss';

body { 
  overflow-y: scroll;
  line-height: 1.3;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.body-bg { background-color: $body-bg !important; }

::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
  display: none;
}

// ::selection      { background: #F7A600 }
// ::-moz-selection { background: #F7A600 }

.text-muted { color: $tertiary; }

.lead { line-height: 1.2; }

.breadcrumb-item a { color: #A59997; }
.breadcrumb-item a:hover { color: #000; }

.form-control, .form-control:focus,
.form-select {
  background-color: white;
}

.modal-content { background-color: $body-bg; }

.react-select__control {
  border: none !important;
  border-radius: 0.2rem !important;
}

.react-select__menu {
  z-index: 2 !important;
}

textarea, input[type="text"]:not([class="react-select__input"]), input[type="number"], input[type="date"], input[type="datetime-local"], input[type="email"], select { border: 1px solid #fff !important; font-size: 16px !important; }
input[type="checkbox"] { border: 1px solid #fff !important; }
label:not([class='switch']):not(.form-check-label):not([class^='btn']):not([class^='list-group-item']) { font-size: 85%; color: #666;}

.btn-primary:hover,
.btn-outline-primary:hover {
  border-color: #F7A600;
  background-color: #F7A600;
  color: #37302C }

.btn-xs, .btn-group-xs > .btn {
  padding: 0.2rem 0.3rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}

.nav-tabs {
  border-bottom: 1px solid $gray-300;
}

.nav-tabs > .nav-item > .nav-link {
  padding: 0.5rem 0.1rem 0.5rem 0.1rem !important;
  margin-right: 1rem;
  color: #A59997;
}

.nav-tabs > .nav-item > .nav-link:hover {
  border-bottom: 1px solid $black;
  color: $black;
  text-decoration: none;
}

.nav-tabs > .nav-item > .nav-link.active {
  border-bottom: 2px solid $black;
  color: $black;
}

.table > thead, .table > .thead {
  vertical-align: bottom;
}

.table > .tbody {
  vertical-align: inherit;
}

.thead, .tbody, .tfoot, .tr, .td, .th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.table th, .table .th, .table td, .table .td { padding-left: 0; }
.table th, .table .th {
  // background-color: inherit;
  font-size: 85%;
  border-bottom: 2px solid #DED8D3 !important;
  cursor: pointer
}

.list-card {
  background-color: white;
  padding: 1em;
  cursor: pointer;
  margin-bottom: 1.5em;
}

.list-card:hover {
  background-color: var(--bs-secondary);
  color: white;
}
