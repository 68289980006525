/* classic CSS */
@keyframes wayfinder {
  0%   { background-color: inherit; }
  20%  { background-color: $active; }
  90%  { background-color: $active; }
  100% { background-color: inherit; }
}

.wayfinder-active {
  animation-name: wayfinder;
  animation-duration: 3s;
}

/* CSS Transition */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.fade-fast-enter {
  opacity: 0;
}
.fade-fast-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-fast-exit {
  opacity: 1;
}
.fade-fast-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
